import React from 'react';

import Overlay from './Overlay';
import Modal from './Modal';
import Iframe from './Iframe';

import config from 'config';

export default function Integration({ partner, language,flow }) {
  let src = `${config.integrationUrl}${language}/login?partner=${partner}`
  let modalSize='modal-small'

  if(flow==='registration'){
    src = `${config.integrationUrl}${language}?partner=${partner}`;
    modalSize='modal-large'
  }

  return (
    <Overlay>
      <Modal size={modalSize}>
        <Iframe src={src} />
      </Modal>
    </Overlay>
  );
}
